.project-card-container {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}

.project-card-container__room-image {
  background-color: map-get($theme-colors, background-sand-200);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.project-card-container__room-image__text {
  font-size: 0.9rem;
  color: map-get($theme-colors, text-dark-300);
  flex: 1;
  display: flex;
  justify-content: center;
}
