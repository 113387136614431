.style-options-container {
  max-width: 1300px;
  flex: 1;
  gap: 1rem;
  column-count: 2;

  &--read-only {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@include mobile {
  .style-options-container {
    display: flex;
    flex-direction: column;
    column-count: 1;
  }
}
