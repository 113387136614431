.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  img {
    border-radius: 0.5rem;
    object-fit: cover;
    max-width: 250px;
  }
}
