.color-option {
  animation: fade-in 0.5s ease-in;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--read-only {
    cursor: default;
    .color-option__circle {
      width: 3rem;
      height: 3rem;
    }
  }

  &__circle {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    color: map-get($theme-colors, default);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.2rem;

    &--selected--light {
      background-image: url('../assets/checkDefault.svg');
    }
    &--selected--dark {
      background-image: url('../assets/checkPrimary.svg');
    }
  }
}
