.profile-picture {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.profile-text {
  line-height: 1.5;
  max-width: 25rem;
  margin-left: 1rem;
}
