.filter-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.5rem;
  padding: 0.625rem 0.75rem;
  max-width: fit-content;

  &__inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    flex-wrap: nowrap;
    width: fit-content;
  }
}

@include mobile {
  .filter-button-container {
    background-color: rgba(0, 0, 0, 0);

    &__inner-container {
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
      flex-wrap: wrap;
      width: fit-content;
    }
    .filter-button {
      height: 2.3rem;
      padding: 0.7rem;
    }
  }
}
