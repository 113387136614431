.profile {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.profile-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
