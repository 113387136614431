.order-confirmation {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    &__icon {
      display: flex;
      height: 8rem;

      &--end {
        align-items: flex-end;
      }
    }
  }
}
