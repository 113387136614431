.language-switcher {
  &:hover {
    cursor: pointer;
  }
  select {
    background-color: transparent;
    border: 0px solid #ccc;

    border-radius: 4px;
    font-size: 14px;

    &:focus {
      border-color: map-get($theme-colors, primary);
      outline: none;
    }
  }
}

@include mobile {
  .language-switcher {
    select {
      font-size: 12px;
    }
  }
}
