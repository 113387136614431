.style-option {
  position: relative;
  display: flex;
  cursor: pointer;
  margin-bottom: 0.7rem;
  animation: 1.5s fade-in ease-in;

  &--read-only {
    cursor: default;
    border: none;
    max-width: 250px;
    min-width: 150px;
  }

  &--selected img {
    border: 2px solid map-get($theme-colors, primary);
  }

  &__checkbox {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__image img {
    border-radius: 0.5rem;
    object-fit: cover;
    width: 100%;
  }
}

@include mobile {
  .style-option--read-only {
    max-width: 100%;
  }
}
