.Collapsible {
  max-width: 30rem;
  &:hover {
    cursor: pointer;
  }

  .trigger-div {
    display: flex;
    gap: 1rem;
    align-items: center;

    .collapsible__title--small {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}
