.package {
  animation: 0.5s fade-in ease-in;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 22rem;
  background-color: map-get($theme-colors, white-default);
  padding-top: 2rem;
  border-radius: 0.5rem;
  border: 1px solid map-get($theme-colors, soft);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

  &--large {
    .Collapsible {
      padding-bottom: 2rem;
    }
  }

  &__best-value-label {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__icon {
    margin-bottom: 0.8rem;
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__price-old-value {
    font-size: 0.9rem;
    font-weight: 300;
    text-decoration: line-through;
    color: map-get($theme-colors, shades-700);
  }

  &__price-value {
    font-size: 1.5rem;
    font-weight: 500;
    color: map-get($theme-colors, primary);
  }

  &__button {
    margin-bottom: 2rem;
  }

  &__included-container {
    flex: 1;
    width: 100%;
    background-color: map-get($theme-colors, stroke-soft);
    padding: 2rem 1.5rem;
    border-radius: 0.5rem;
  }

  &__included__title {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__included__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.2rem;
    }
  }

  &__more-included {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
    font-size: 0.8rem;
  }

  &__more-included__items {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
    margin-top: 1rem;
  }

  &__more-included__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__hover-icon {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}
