.exit-project {
  max-width: 640px;
}

.exit-project-button-container {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 20px;
}
