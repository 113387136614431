.design-package-selection {
  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__subtitle {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &--strong {
        font-weight: bold;
      }
    }
  }
}

.design-package-selection__profile-picture-side {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.design-package-selection__info-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  strong {
    font-weight: bold;
  }
}

.package-selection-questions {
  display: flex;
  flex-direction: column;
  align-items: center;

  .package-selection-question-text {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
  }

  .package-selection-question-title {
    text-align: center;
    margin-bottom: 1rem;
  }
}

@include mobile {
  .design-package-selection__info-text {
    max-width: 100%;
  }
}
