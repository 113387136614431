.enumeration-options-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

@include mobile {
  .enumeration-options-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
