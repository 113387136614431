.color-options-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &--read-only {
    align-items: flex-start;
  }

  &__inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    flex-wrap: wrap;
    max-width: 60rem;
  }
}

@include mobile {
  .color-options-container__inner-container {
    justify-content: center;
    gap: 2rem;
  }
}
