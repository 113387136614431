.project-details__content {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__row {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__section {
    background-color: map-get($theme-colors, white-default);
    padding: 1rem;
    border: 1px solid map-get($theme-colors, soft);
    min-width: 321px;
    min-height: 98px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-evenly;
    flex: 1;
    &__content {
      display: flex;
      gap: 1rem;
      flex-wrap: no-wrap;
      flex: 1;
    }
    &__header {
      color: map-get($theme-colors, secondary);
      display: flex;
      align-items: center;
      gap: 0.5rem;
      &__icon {
        background-color: map-get($theme-colors, background-sand-200);
        padding: 0.5rem;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__right-part {
        display: flex;
        flex-direction: column;
      }
      &__large-text {
        font-size: 1rem;
        color: map-get($theme-colors, text-highlight);
        font-weight: 800;
      }
    }

    &__item-value {
      font-weight: 700;
      color: map-get($theme-colors, text-dark);
    }

    &__vertical {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      width: fit-content;
      padding-top: 0.5rem;
      padding-left: 0.5rem;
    }

    &__horizontal-item {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
    }
  }
}
