.style-selection__header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
.filter-title {
  margin-bottom: 0.5rem;
  font-weight: 600;
  margin-top: 3rem;
}
