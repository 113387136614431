.wizard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__step {
    color: map-get($theme-colors, shades);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid map-get($theme-colors, shades);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    &--active {
      background-color: map-get($theme-colors, primary);
      border: 1px solid map-get($theme-colors, primary);
      color: map-get($theme-colors, white-default);
    }
  }

  &__line {
    height: 1px;
    width: 3rem;
    background-color: map-get($theme-colors, shades);

    &--first {
      width: 8rem;
    }

    &--active {
      background-color: map-get($theme-colors, primary);
    }
  }
}

.mobile-wizard {
  display: none;
}

@include mobile {
  .mobile-wizard {
    display: flex;
    margin-top: 2rem;
  }
}
