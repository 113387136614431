.room-option {
  animation: 1s fade-in ease-in;
  position: relative;
  width: 15rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid map-get($theme-colors, soft);
  background-color: map-get($theme-colors, white-default);

  &--selected {
    border: 2px solid map-get($theme-colors, primary);
  }

  &--read-only {
    cursor: default;
    border: none;
  }

  &__checkbox {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
}

@include mobile {
  .room-option {
    position: static;
    height: 5rem;
    width: 100%;
    flex: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &__checkbox {
      position: static;
      order: 2;
    }

    &__image {
      display: flex;
      flex-direction: row;
    }
  }
}
