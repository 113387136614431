.color-label {
  padding: 0.2rem 0.75rem;
  background-color: grey;
  border-radius: 4px;
  color: map-get($theme-colors, white-default);
  font-size: 0.9rem;

  &--warning {
    background-color: map-get($theme-colors, warning-soft);
    color: map-get($theme-colors, warning);
  }

  &--success {
    background-color: map-get($theme-colors, success-soft);
    color: map-get($theme-colors, success);
  }
}
