.progress-bar {
  width: 100%;
  height: 0.5rem;
  background-color: map-get($theme-colors, shades-200);
  border-radius: 20px;
  margin: 10px 0;
  overflow: hidden;

  .progress-bar__step {
    height: 100%;
    width: 0;
    border-radius: 20px;
    transition: width 3s ease-in-out;
  }
  .progress-bar__step--step-1 {
    width: 25%;
    background-color: map-get($theme-colors, warning);
  }

  .progress-bar__step--step-2 {
    width: 50%;
    background-color: map-get($theme-colors, warning);
  }

  .progress-bar__step--step-3 {
    width: 75%;
    background-color: map-get($theme-colors, warning);
  }
  .progress-bar__step--step-4 {
    width: 100%;
    background-color: map-get($theme-colors, success);
  }
}
