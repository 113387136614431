.textarea {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 30rem;
  gap: 0.5rem;

  &__label {
    font-size: 0.8rem;
    font-weight: 400;
    color: #000000;
  }

  &__input {
    resize: none;
    outline: none;
    padding: 0.3rem;
    border-radius: 0.5rem;
    border: 1px solid map-get($theme-colors, soft);
    font-size: 0.9rem;
    width: 100%;

    &:focus {
      border: 1px solid map-get($theme-colors, primary);
    }
  }
}
