.project-card {
  animation: 1s fade-in ease-in;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem;
  justify-content: space-evenly;
  background-color: map-get($theme-colors, stroke-soft);
  border: 1px solid map-get($theme-colors, soft);
  width: 436px;

  &__room {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: map-get($theme-colors, text-soft);
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 4rem;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
  }

  &__row__item {
    width: 50%;
    min-height: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
