.enumeration-option {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
  max-width: 30rem;
  cursor: pointer;
  border: 1px solid map-get($theme-colors, soft);
  background-color: map-get($theme-colors, white-default);
  padding: 0.5rem 1rem;

  &--selected {
    border: 2px solid map-get($theme-colors, primary);
  }

  &__caption {
    font-size: 0.8rem;
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 0.1rem;
  }
}

@include mobile {
  .enumeration-option {
    width: 100%;
    justify-content: flex-start;
  }
}
