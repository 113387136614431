.page-with-sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.layout-container {
  animation: 0.25s fade-in ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.button-container-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.label {
  color: map-get($theme-colors, text-soft);
  font-weight: 400;
  font-size: 0.9rem;
}

.input-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.info-text {
  background-color: map-get($theme-colors, succes-soft);
  border: 1px solid map-get($theme-colors, success);
  border-radius: 6px;
  padding: 0.8rem;
  font-size: 0.8rem;
}

.input-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@include mobile {
  .input-fields {
    flex-direction: column;
  }

  .layout-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
