.review-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  padding: 1rem;

  .review-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    padding: 0.5rem;
  }

  .slick-slider {
    width: 716px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .slick-prev:before,
  .slick-next:before {
    color: map-get($theme-colors, default);
  }
}

@include mobile {
  .review-container {
    .slick-slider {
      width: 70vw;
    }
  }
}
