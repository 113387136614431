.project-start {
  padding-top: 2rem;
  height: 100%;
  display: flex;
  gap: 5rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__input-block {
    background-color: map-get($theme-colors, white-default);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;
    width: 450px;
    border-radius: 4px;
  }
}

@include mobile {
  .project-start {
    gap: 4rem;
    &__input-block {
      padding: 1rem;
      max-width: 450px;
      width: 90vw;
    }
  }
}
