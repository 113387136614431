.checkbox {
  &:hover {
    cursor: pointer;
  }
  &__error {
    font-size: 0.8rem;
    color: map-get($theme-colors, error);
  }

  &__input {
    font-size: 0.8rem;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }

    &--invalid {
      border: 1px solid map-get($theme-colors, error);
    }
  }

  &__label {
    &:hover {
      cursor: pointer;
    }
  }
}
