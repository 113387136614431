.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &__dropArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    background-color: map-get($theme-colors, stroke-soft);
    border-radius: 0.5rem;
    border: 1px solid map-get($theme-colors, soft);
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__title {
      font-size: 1.2rem;
      font-weight: 500;
      text-align: center;
    }

    &__subtitle {
      font-size: 0.9rem;
      text-align: center;
    }

    &__or {
      font-size: 0.9rem;
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.2rem;
    max-width: 60rem;

    &__file {
      position: relative;
      max-height: 20rem;
      max-width: 19rem;
      border-radius: 0.5rem;
      animation: fade-in 0.5s ease-in-out;

      &__deleteIcon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
      }

      &__image {
        object-fit: cover;
        max-height: 20rem;
        max-width: 19rem;
        border-radius: 0.3rem;
      }
    }
  }
}
