.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  &__main-container {
    position: absolute;
    background-color: #eee;
    border: 1px solid #555;
    border-radius: 5px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
    top: 30%;
    width: fit-content;

    &__exit-container {
      display: flex;
      justify-content: flex-end;

      &__button {
        padding-top: 0.5rem;
        padding-right: 1rem;
        padding-left: 1rem;
        cursor: pointer;
      }
    }
  }

  &__inner-container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
}
