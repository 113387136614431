.round-image {
  animation: 1s fade-in ease-in;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  overflow: hidden;
  overflow: hidden;
  border: 0.1rem solid map-get($theme-colors, shades);
}

.round-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
