.loader-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  &__loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000;
    box-shadow: -20px 0px, 20px 0px;
    animation: l18 1s infinite;
  }
}

@keyframes l18 {
  25% {
    box-shadow: -20px -20px, 20px 20px;
  }
  50% {
    box-shadow: 0px -20px, 0px 20px;
  }
  75% {
    box-shadow: 20px -20px, -20px 20px;
  }
  100% {
    box-shadow: 20px 0px, -20px 0px;
  }
}
