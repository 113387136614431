@import '../variables.scss';

.navbar {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background-color: map-get($theme-colors, white-default);

  &__logo {
    flex: 1;
    display: flex;
    justify-content: flex-start;

    &:hover {
      cursor: pointer;
    }
  }

  &__middle-content {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__end-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    gap: 2rem;
    flex-wrap: nowrap;
    text-wrap: nowrap;

    &:hover {
      cursor: pointer;
    }
  }
}

@include mobile {
  .navbar__middle-content {
    display: none;
  }

  .navbar__end-content {
    gap: 1rem;
  }
}
