.button {
  width: fit-content;
  height: 2.7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  cursor: pointer;
  color: map-get($theme-colors, default);
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  background-color: map-get($theme-colors, white-default);
  border: 1px solid map-get($theme-colors, soft);
  gap: 0.5rem;

  &:hover {
    background-color: map-get($theme-colors, soft);
  }

  &--primary {
    background-color: map-get($theme-colors, primary);
    border: map-get($theme-colors, primary);
    color: map-get($theme-colors, white-default);
    &:hover {
      background-color: color.adjust(map-get($theme-colors, primary), $lightness: 10%);
    }
  }

  &--success {
    background-color: map-get($theme-colors, success);
    border: map-get($theme-colors, success);
    &:hover {
      background-color: color.adjust(map-get($theme-colors, success), $lightness: 10%);
    }
  }

  &--link {
    background-color: transparent;
    border: none;
    flex-direction: row-reverse;
    gap: 0.5rem;
    padding-left: 0;
    &:hover {
      opacity: 0.5;
      background-color: transparent;
    }
  }

  &__text {
    vertical-align: middle;
    white-space: nowrap;
  }

  &__icon {
    padding-top: 0.2rem;
  }
}
