@use 'sass:color';

@mixin mobile() {
  @media only screen and (max-width: 850px) {
    @content;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-up {
  animation: 1s fade-in ease-in;
}

@keyframes fade-in-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fade-in-up {
  animation: 1s fade-in-up ease-in;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@import './variables.scss';
@import './components/navbar.scss';
@import './components/wizard.scss';
@import './components/colorOption.scss';
@import './components/colorOptionsContainer.scss';

@import './components/roomOption.scss';
@import './components/roomOptionsContainer.scss';
@import './components/button.scss';
@import './components/filterButton.scss';
@import './components/filterButtonContainer.scss';
@import './components/buttonContainer.scss';
@import './components/styleOption.scss';
@import './components/styleOptionsContainer.scss';
@import './components/input.scss';
@import './components/checkbox.scss';
@import './components/page.scss';
@import './components/textarea.scss';
@import './components/enumerationOption.scss';
@import './components/enumerationOptionsContainer.scss';
@import './components/packageOption.scss';
@import './components/packageOptionsContainer.scss';
@import './components/dropzone.scss';
@import './components/projectCard.scss';
@import './components/projectCardContainer.scss';
@import './components/progressBar.scss';
@import './components/roundImage.scss';
@import './components/orderConfirmation.scss';
@import './components/sidebar.scss';
@import './components/colorLabel.scss';
@import './components/languageSwitcher.scss';
@import './components/modal.scss';
@import './components/starRating.scss';
@import './components/reviewContainer.scss';
@import './components/loader.scss';
@import './components/banner.scss';
@import './components/collapsible.scss';
@import './components/profile.scss';
@import './components/uploadedImages.scss';
@import './pages/exitProject.scss';
@import './pages/designPackageSelection.scss';
@import './pages/styleSelection.scss';
@import './pages/projectStart.scss';
@import './pages/projectSteps.scss';
@import './pages/personalDetails.scss';
@import './pages/orderConfirmation.scss';
@import './pages/projectInformation.scss';
@import './pages/projectDetails.scss';

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get($theme-colors, shades-900);
  background-color: map-get($theme-colors, background);
  font-size: 0.9rem;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: transparent;
}

h3 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
}

h4 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}

h5 {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

a {
  color: map-get($theme-colors, default);
  font-size: 0.8rem;
}

p {
  font-weight: 300;
}

span {
  font-weight: 300;
  font-size: 0.8rem;
  max-width: 800px;
}

textarea {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

label {
  color: map-get($theme-colors, text-soft);
}

@include mobile {
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.2rem;
  }
}

.small-bottom-margin {
  margin-bottom: 1rem;
}
