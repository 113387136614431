.project-information {
  ul {
    margin-top: 0px;
    li {
      margin-bottom: 0.5rem;
    }
  }

  &__about-container {
    background-color: map-get($theme-colors, stroke-soft);
    border-radius: 5px;
    padding: 1rem;
    margin-top: 1rem;
    max-width: 30rem;
  }

  &__about-content {
    padding: 1rem;
    margin-top: 1rem;
  }
}
