.filter-button {
  width: fit-content;
  height: 2.2rem;
  padding-left: 1.3rem;
  padding-right: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  cursor: pointer;
  color: map-get($theme-colors, default);
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  background-color: map-get($theme-colors, white-default);
  border: 1px solid map-get($theme-colors, soft);
  gap: 0.5rem;

  &:hover {
    background-color: map-get($theme-colors, soft);
  }
  &--selected {
    background-color: map-get($theme-colors, soft-filter);
    border: 1px solid map-get($theme-colors, primary);
  }
}
