.sidebar {
  display: flex;
  height: 100%;

  &__username {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.7rem;
    margin-left: 1.2rem;
    padding-bottom: 2rem;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }

  &__toggle {
    position: absolute;
    background-color: transparent;
    border: none;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .ps-broken {
    .ps-sidebar-container {
      background-color: map-get($theme-colors, white-default);
      opacity: 1;
    }
  }

  &__language-switcher {
    width: 100%;
    display: flex;
    padding-left: 1rem;
  }
}
