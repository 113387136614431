.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: map-get($theme-colors, white-default);
  border-radius: 3.5rem;
  padding: 0.625rem 0.75rem;
  max-width: fit-content;

  &__inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    flex-wrap: nowrap;
    width: fit-content;
  }
}

@include mobile {
  .button-container {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    bottom: 0;
    opacity: 0.9;

    &--transparent {
      opacity: 0.7;
    }

    &__inner-container {
      display: flex;
      flex-direction: column-reverse;
      gap: 0.4rem;
      flex-wrap: wrap;
      width: fit-content;
    }
    .button {
      width: 22.4rem;
      height: 3rem;

      &--primary {
        .button__icon {
          display: none;
        }
      }
    }
  }
}
