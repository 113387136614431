.banner {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: map-get($theme-colors, primary-soft);
  text-align: start;
  gap: 3px;
  white-space: nowrap;
  flex-wrap: wrap;
}
