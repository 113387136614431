.input {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  max-width: 30rem;

  &__label {
    font-size: 0.8rem;
    font-weight: 400;
    color: #000000;
  }

  &__error {
    font-size: 0.8rem;
    color: map-get($theme-colors, error);
  }

  &__field {
    outline: none;
    height: 2.5rem;
    font-size: 0.9rem;
    padding-left: 0.3rem;
    border-radius: 0.5rem;
    border: 1px solid map-get($theme-colors, soft);

    &:focus {
      border: 1px solid map-get($theme-colors, primary);
    }

    &--invalid {
      border: 1px solid map-get($theme-colors, error);
    }
  }
}
